/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../../_helpers';
import { ROUTES } from '../../../../../app/Routes.models';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import defaultAvatar from './../../../../../assets/image/blank.png';

const { REACT_APP_MODE, REACT_APP_REQ_DEV, REACT_APP_REQ_PROD } = process.env;
const ENDPOINT = REACT_APP_MODE === 'dev' ? REACT_APP_REQ_DEV : REACT_APP_REQ_PROD;

export function UserProfileDropdown() {
  const intl = useIntl();
  const { user } = useSelector(state => state.auth);
  const { avatar } = useSelector(state => state.auth.user);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, 'extras.user.dropdown.style') === 'light',
    };
  }, [uiService]);

  const { authState } = useSelector(state => ({ authState: state.auth }));
  const { isAdmin } = authState;

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-user-profile">
        <div className="d-flex align-items-center mr-4 mr-lg-0">
          <div className="btn btn-icon btn-hover-transparent-white btn-lg px-md-2 w-md-auto">
            <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>{' '}
            <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
              {user.name} {user.surname}
            </span>
            <span className="symbol symbol-35">
              <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">
                {user.name[0]}
              </span>
            </span>
          </div>
          {isAdmin && (
            <span className="svg-icon svg-icon-xl svg-icon-warning">
              <SVG src={toAbsoluteUrl('/media/svg/icons/General/Star.svg')} />
            </span>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={avatar ? avatar : defaultAvatar} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.name} {user.surname}
                </div>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/misc/bg-1.jpg')})`,
              }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">{user?.name[0]}</span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.name} {user.lastname}
              </div>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          {user.isAdmin === true && (
            <a
              href={`${ENDPOINT}admin`}
              target="_blank"
              rel="noopener noreferrer"
              className="navi-item px-8 cursor-pointer"
            >
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon-buildings text-success" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">Корпоративный портал</div>
                  <div className="text-muted">Перейти корпоративный в портал</div>
                </div>
              </div>
            </a>
          )}

          <Link to={ROUTES.USER_PROFILE} className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">{intl.formatMessage({ id: 'MY_PROFILE' })}</div>
                <div className="text-muted">{intl.formatMessage({ id: 'ACCOUNT_SET_AND_MORE' })}</div>
              </div>
            </div>
          </Link>

          <Link to={ROUTES.USER_PROFILE_BUILDER} className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-gear text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">Настройки</div>
                <div className="text-muted">Настройки интерфейса</div>
              </div>
            </div>
          </Link>

          {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-mail text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a> */}

          {/*  <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-rocket-1 text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a> */}

          {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-hourglass text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a> */}
          <div className="navi-separator mt-3"></div>
          <div className="navi-footer  px-8 py-5">
            <Link to={ROUTES.LOGOUT} className="btn btn-light-primary font-weight-bold">
              {intl.formatMessage({ id: 'SIGN_OUT' })}
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
